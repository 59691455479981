import { LABELS } from "./labels";
import router from "@/router/index";
import { throwIfNotFound } from "./utils";

import projectHandler from "./project";

export const HANDLER = [
    // Project Reservation
    {
        label: LABELS.PROJECT_RESERVATION_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "unitId", params);
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageProjectReservationDetails",
                params: {
                    unitId: params.unitId,
                    id: params.projectPurchaseId
                }
            };

            router.push(route);
        }
    },
    {
        label: LABELS.ASSIGNED_PROJECT_RESERVATION_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "unitId", params);
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageAssignedProjectReservationDetails",
                params: {
                    unitId: params.unitId,
                    id: params.projectPurchaseId
                }
            };

            router.push(route);
        }
    },
    // Project Sale
    {
        label: LABELS.PROJECT_SALES_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ProjectSalesDetail",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    {
        label: LABELS.ASSIGNED_PROJECT_SALES_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "AssignedProjectSalesDetail",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    // Project Booking
    {
        label: LABELS.PROJECT_BOOKING_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageProjectBookingDetail",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    {
        label: LABELS.ASSIGNED_PROJECT_BOOKING_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageAssignedProjectBookingDetail",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    // Project Mortgage Loan
    {
        label: LABELS.PROJECT_LOAN_LIST,
        handle: function () {
            let route = {
                name: "ManageProjectMortgageBankLoans"
            };
            router.push(route);
        }
    },
    {
        label: LABELS.ASSIGNED_PROJECT_LOAN_LIST,
        handle: function () {
            let route = {
                name: "ManageAssignedProjectMortgageBankLoans"
            };
            router.push(route);
        }
    },
    {
        label: LABELS.PROJECT_LOAN_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "bankLoanId", params);

            let route = {
                name: "ManageProjectMortgageBankLoansDetail",
                params: {
                    id: params.bankLoanId
                }
            };
            router.push(route);
        }
    },
    {
        label: LABELS.ASSIGNED_PROJECT_LOAN_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "bankLoanId", params);

            let route = {
                name: "ManageAssignedProjectMortgageBankLoansDetail",
                params: {
                    id: params.bankLoanId
                }
            };
            router.push(route);
        }
    },
    // Project Lawyer
    {
        label: LABELS.PROJECT_LAWYER_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageProjectLegalProcessDetail",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    {
        label: LABELS.ASSIGNED_PROJECT_LAWYER_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageAssignedProjectLegalProcessDetail",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    // Project Refund
    {
        label: LABELS.PROJECT_REFUND_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageProjectBookingRefunds",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    {
        label: LABELS.ASSIGNED_PROJECT_REFUND_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "projectPurchaseId", params);

            let route = {
                name: "ManageAssignedProjectBookingRefunds",
                params: {
                    id: params.projectPurchaseId
                }
            };
            router.push(route);
        }
    },
    // Subsale
    {
        label: LABELS.SUBSALE_PURCHASE_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "subsalePurchaseId", params);

            let route = {
                name: "SubsalesBookingDetail",
                params: {
                    id: params.subsalePurchaseId
                }
            };
            router.push(route);
        }
    },
    // Rent
    {
        label: LABELS.RENT_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "rentalId", params);

            let route = {
                name: "RentBookingDetail",
                params: {
                    id: params.rentalId
                }
            };
            router.push(route);
        }
    },
    // Auction
    {
        label: LABELS.AUCTION_DETAIL,
        handle: function (params) {
            throwIfNotFound(this.label, "auctionRegistrationId", params);

            let route = {
                name: "AuctionRegistrationDetail",
                params: {
                    id: params.auctionRegistrationId
                }
            };
            router.push(route);
        }
    },

    // ================================ PROJECT ================================
    {
        label: LABELS.PROJECT_ADDED,
        handle: projectHandler.toProjectDetail
    },
    {
        label: LABELS.PROJECT_UPDATED,
        handle: projectHandler.toProjectDetail
    },
    {
        label: LABELS.PROJECT_BANKER_ALLOCATED,
        handle: projectHandler.toProjectBankerAllocation
    },
    {
        label: LABELS.PROJECT_BANKER_REMOVE,
        handle: projectHandler.toProjectBankerAllocation
    },
    {
        label: LABELS.PROJECT_LAWYER_ALLOCATED,
        handle: projectHandler.toProjectLawyerAllocation
    },
    {
        label: LABELS.PROJECT_LAWYER_REMOVED,
        handle: projectHandler.toProjectLawyerAllocation
    },
    {
        label: LABELS.PROJECT_PHASE_ADDED,
        handle: projectHandler.toProjectDetail
    },
    {
        label: LABELS.PROJECT_PHASE_UPDATED,
        handle: projectHandler.toProjectDetail
    },
    {
        label: LABELS.PROJECT_UNIT_TYPE_ADDED,
        handle: projectHandler.toProjectUnitTypeDetail
    },
    {
        label: LABELS.PROJECT_UNIT_TYPE_UPDATED,
        handle: projectHandler.toProjectUnitTypeDetail
    },
    {
        label: LABELS.PROJECT_FLOOR_PLAN_ADDED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_FLOOR_PLAN_UPDATED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_ADDED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_UPDATED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_BANKER_ALLOCATED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_BANKER_REMOVED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_LAWYER_SPA_UPDATED,
        handle: projectHandler.toProjectFloorPlanDetail
    },
    {
        label: LABELS.PROJECT_UNIT_LAWYER_LA_UPDATED,
        handle: projectHandler.toProjectFloorPlanDetail
    }
];
