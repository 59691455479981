<template>
  <div class="agency-topbar-wrapper">
    <topbar :isActive="isMenuOpen">
      <template #right-section>
        <div class="d-flex justify-content-end">
          <!-- Notifications -->
          <DropdownNotifications
            class="mr-1"
            :notificationHandler="notificationHandler"
            :notificationAPI="notificationAPI"
          ></DropdownNotifications>

          <!-- Profile Info -->
          <div class="d-flex align-items-center">
            <dropdown-button
              :buttonStyle="{ background: 'transparent', padding: 0 }"
            >
              <template #buttonContent>
                <avatar
                  :src="getAvatarURL()"
                  :name="user.name"
                  :size="36"
                  class="cursor-pointer"
                ></avatar>
              </template>
              <div class="profile-dropdown card">
                <div class="d-flex p-3">
                  <avatar
                    :src="getAvatarURL()"
                    :name="user.name"
                    :size="48"
                    class="mr-2"
                  ></avatar>
                  <div>
                    <p class="profile-name mb-1">{{ user.name }}</p>
                    <chip>
                      {{ _.get(user, "rolePermission.name") }}
                    </chip>
                    <span class="profile-subinfo">
                      {{ user.actualName }}
                    </span>
                    <span class="profile-subinfo">{{ user.email }}</span>
                  </div>
                </div>
                <div class="d-flex justify-content-center pb-3">
                  <fd-button
                    class="bordered main w-100 mx-2"
                    @click.stop="$router.push({ name: 'ManageAccount' })"
                  >
                    Manage Account
                  </fd-button>
                </div>
              </div>
            </dropdown-button>
          </div>
        </div>
      </template>
    </topbar>
  </div>
</template>

<script>
import { HANDLER as notificationHandler } from "@/helpers/notification/handler";
import { NotificationAPI } from "@/modules/Notification/api/notification";
import { agencyAPI } from "@/modules/api-config";

let notificationAPI = new NotificationAPI(agencyAPI, "Agency CP");

export default {
  components: {
    Topbar: () =>
      import("@/components/GlobalComponents/FdLayout/TopbarComponents/Topbar"),
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    Chip: () => import("@/components/GlobalComponents/Chip"),
    Avatar: () => import("@/components/GlobalComponents/Avatar"),
    DropdownNotifications: () =>
      import("@/modules/Notification/components/DropdownNotifications")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      notificationHandler,
      notificationAPI
    };
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    },
    user() {
      return this.$auth.user();
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getAvatarURL() {
      return this.$getFileURL(this.$auth.user().avatar?.[0]);
    }
  }
};
</script>

<style lang="scss">
.profile-dropdown {
  .profile-name {
    font-size: 16px;
  }
  .profile-subinfo {
    font-weight: normal;
    color: #888;
    display: block;
  }
}
</style>
