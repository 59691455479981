import router from "@/router/index";
import { throwIfNotFound } from "./utils";

export function toProjectDetail(params) {
    throwIfNotFound(this.label, "projectId", params);

    let route = {
        name: "ManageProjectsDetails",
        params: {
            id: params.projectId
        }
    };
    router.push(route);
}

export function toProjectUnitTypeDetail(params) {
    throwIfNotFound(this.label, "projectId", params);
    throwIfNotFound(this.label, "projectUnitTypeId", params);

    let route = {
        name: "ManageProjectUnitTypesDetails",
        params: {
            projectId: params.projectId,
            id: params.projectUnitTypeId
        }
    };
    router.push(route);
}

export function toProjectFloorPlanDetail(params) {
    throwIfNotFound(this.label, "projectId", params);
    throwIfNotFound(this.label, "projectUnitTypeId", params);
    throwIfNotFound(this.label, "projectFloorPlanId", params);

    let route = {
        name: "ManageProjectFloorPlanDetails",
        params: {
            projectId: params.projectId,
            unitTypeId: params.projectUnitTypeId,
            id: params.projectFloorPlanId
        }
    };
    router.push(route);
}

export function toProjectBankerAllocation(params) {
    throwIfNotFound(this.label, "projectId", params);

    let route = {
        name: "ProjectAllocateBankers",
        params: {
            id: params.projectId
        }
    };
    router.push(route);
}

export function toProjectLawyerAllocation(params) {
    throwIfNotFound(this.label, "projectId", params);

    let route = {
        name: "ProjectAllocateLawyers",
        params: {
            id: params.projectId
        }
    };
    router.push(route);
}

export default {
    toProjectDetail,
    toProjectUnitTypeDetail,
    toProjectFloorPlanDetail,

    toProjectBankerAllocation,
    toProjectLawyerAllocation
};
